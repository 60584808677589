<template>
    <div class="teams" v-if="pageLoaded">
        <div class="settings__main">
            <div class="settings__heading">
                <div class="content">
                    <div class="content__section">
                        <div class="content__subtitle">
                            Кто может приглашать в команду
                        </div>
                        <div class="content__text">
                            Помимо администраторов
                        </div>
                        <ContextPicker 
                            :remoteValue="settings.owners.roles" 
                            :options="rolesOptions" 
                            label="Роли"
                            :loading = isLoading
                            :page="currentRolesPage"
                            @search="searchRoles($event)"
                            @paginate="paginateRoles($event)"
                            @blur="setRoles($event, 'viewers')"
                            :class="{ 'context-picker_off': settings.access_all }" 
                        />
                        <ContextPicker 
                            :remoteValue="settings.owners.users" 
                            :options="getUsers" 
                            label="Пользователи"
                            :loading = isLoading
                            :page="currentUsersPage"
                            @search="searchUsers($event)"
                            @paginate="paginateUsers($event)"    
                            @blur="setUsers($event, 'viewers')" 
                            :class="{ 'context-picker_off': settings.access_all }" 
                        />
                        <div class="toggle-wrapper">
                            <span class="toggle__label">
                                Все
                            </span>
                            <ToggleSwitch  v-model="settings.access_all" />
                        </div>
                    </div>
                    <Divider />
                    <div class="content__section">
                        <div class="content__subtitle">
                            Кто видит задачи пользователя в его профиле
                        </div>
                        <div class="profile-viewers">
                            <div class="profile-viewers__radio-btns">
                                <RadioButton @change="setTasksViewers($event)" :value="settings.tasks_viewers"
                                    :radioLabels="radioLabelsViewers" class="radio-btn__all" />
                                <!-- <RadioButton @change="setTasksViewers($event)" :value="settings.tasks_viewers === '2'" label="Администраторы" class="radio-btn__admin"/> -->
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div class="content__section">
                        <div class="content__subtitle">
                            Сортировка по-умолачнию
                        </div>
                        <div class="profile-viewers">
                            <div class="profile-viewers__radio-btns">
                                <RadioButton @change="setDefaultSorting($event)" :value="settings.default_sorting"
                                    :radioLabels="radioLabelsSorting" class="radio-btn__all" />
                                <!-- <RadioButton @change="setDefaultSorting($event)" :value="settings.default_sorting === '2'" label="По дате регистрации (новенькие снизу)" class="radio-btn__admin"/> -->
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div class="content__section user-history">
                        <div class="content__subtitle">
                            Пользователи
                        </div>
                        <div class="user-history__selector-bar-wrapper" v-if="!$isMobile">
                            <SelectorBar :options="userActivities" @change="setUserActivity($event)"/> 
                        </div>
                        <div class="user-history__users-list">
													<UserList @delete="userDeleted($event)" :users="getAllUsers" :activityStatus="activityStatus" @update="updateHistory"/>
                        </div>
                    </div>
                    <div class="content__section owner">
                        <div class="content__subtitle">
                            Владелец
                        </div>
                        <div class="content__text">
                            Владельца можно поменять на кого то из администраторов
                        </div>
                        <UserList :user="owner" class="owners" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SelectorBar from '@/modules/settings/components/selector-bar/SelectorBar.vue'
import RadioButton from '@/modules/settings/components/radio-button/RadioButton.vue'
import Divider from '@/modules/settings/components/divider/Divider.vue';
import ContextPicker from '@/modules/settings/components/context-picker/ContextPicker.vue';
import ToggleSwitch from '@/components/ui/toggle-switch/ToggleSwitch.vue';
import ApiClient from '@/api/api.client';
import UserList from '@/modules/settings/views/teams/components/UserList.vue'
import Notify from '@/services/helpers/notify';
import RolesModule from '@/store/modules/roles/RolesModule';
import TeamsModule from '@/store/modules/teams/TeamsModule';
import SettingsModule from '@/store/modules/settings/SettingsModule';
export default {
    components: { ContextPicker, SelectorBar, ToggleSwitch, Divider, RadioButton, UserList},
    data() {
        return {
            firstSearchUsers: false,
            firstSearchRoles: false,
            currentUsersPage: 2,
            currentRolesPage: 2,

            isLoading: false,
            userActivities: 
            [
                {
                    title: 'Активные',
                    value: 'active',
                    count: 75,
                },
                {
                    title: 'Неактивные',
                    value: 'not_active',
                    count: 7,
                },
                {
                    title: 'Удаленные',
                    value: 'deleted',
                    count: 7,
                },
            ],
            radioLabelsViewers: 
            [
                {
                    label: 'Все', value: 'all',
                },
                { 
                    label: 'Администраторы', value: 'admins' ,
                },
            ],
            radioLabelsSorting:
            [
                {
                    label: 'По оценке', value: 'rating',
                }, 
                { 
                    label: 'По дате регистрации (новенькие снизу)', value: 'created_at' ,
                },
            ],
            pageLoaded: false,
            userHistory: null,
            currentUsersSelected: '',
            page: 1,
            searchQuery: null,
            users: '',
            owner: 
            {
                avatar: '',
                first_name: '',
                id: '',
            },
            settings: {
                enabled: false,
                owners: {
                    access_all: false,
                    roles: [],
                    users: [],
                },
                tasks_viewers: '',
                default_sorting: '',
            },
            activityStatus: 'active',
        };
    },
    methods: {
				filterSelectedUsers() {
					const loadedUserIds = TeamsModule.teams.map(user => user.id);
					const filteredUsers = this.settings.owners.users.filter(selectedUser => 
						loadedUserIds.includes(selectedUser.id),
					);
					
					if (filteredUsers.length !== this.settings.owners.users.length) {
						this.settings.owners.users = filteredUsers;
					}
				},
        updateHistory()
        {
            this.loadUserActivities(this.activityStatus)
        },
        setRoles(roles) {
            this.settings.owners.roles = roles;
        },
        searchUsers(value)
        {
            this.firstSearchUsers = true;
            this.currentUsersPage = 1;
            TeamsModule.clearTeams();
            this.loadUsers(value)
        },
        searchRoles(value)
        {
            this.firstSearchRoles = true;
            this.currentRolesPage = 1;
            // RolesModule.clearRoles();
            this.loadRoles(value);
        },
        paginateUsers(value)
        {
            if(this.firstSearchUsers)
            {
                this.currentUsersPage++;
                value.page = this.currentUsersPage
            }
            this.loadUsers(value);
            this.firstSearchUsers = false
            this.currentUsersPage++;
        },
        paginateRoles(value)
        {
            if(this.firstSearchRoles)
            {
                this.currentRolesPage++;
                value.page = this.currentRolesPage
            }
            this.loadRoles(value)
            this.firstSearchRoles = false;
            this.currentRolesPage++;
        },
        userDeleted(id)
        {
            this.updateHistory();
            this.currentUsersSelected = this.currentUsersSelected.filter((el) => el.id != id);
        },
        setTasksViewers(viewersStatus) {
            this.settings.tasks_viewers = viewersStatus
        },
        setDefaultSorting(defaultSorting) {
            this.settings.default_sorting = defaultSorting
        },
        async loadUserActivities(status = 'active')
        {
            this.userHistory =  (await ApiClient.user.getUserHistory())
            this.currentUsersSelected = this.userHistory[status]

            this.userActivities[0].count = Number(this.userHistory['active'].length);
            this.userActivities[1].count = Number(this.userHistory['not_active'].length);
            this.userActivities[2].count = Number(this.userHistory['deleted'].length);
        },
        setUsers(users) {
            this.settings.owners.users = users;
        },

        setUserActivity(activity)
        {
            this.activityStatus = activity
            this.currentUsersSelected = this.userHistory[activity]
        },
        async loadRoles(payload)
        {
            this.isLoading = true;
            await RolesModule.fetchRoles(payload).finally(() => 
            {
                this.isLoading = false;
            })
        },
        async loadSettings() {
            // if(!this.sectionSettings.length)
            // {
			// }
			await SettingsModule.fetchGeneralSettings();
            this.settings = this.sectionSettings.filter(el => el.slug === 'team')[0].settings;
        },
        async loadUsers(payload) {
            this.isLoading = true;
            await TeamsModule.fetchTeams(payload).finally(() => 
            {
                this.isLoading = false;
								this.filterSelectedUsers();
            });
        },
        updateChanges(value) {
            if (this.$route.path.indexOf('teams')) {
                ApiClient.settingsTeams.setTeamsSettings(value).then(() => {
                    Notify.success('Изменения сохранены');
                });
            }
        },
        async getOwner()
        {
            const ownerData = (await ApiClient.settings.getOwner());
            this.owner.avatar = ownerData.data.avatar;
            this.owner.first_name = ownerData.data.first_name;
            this.owner.id = ownerData.data.id;
        },
    },
    computed: {
        sectionSettings()
        {
            return SettingsModule.getGeneralSettings
        },
        rolesOptions() {
            return RolesModule.roles.map((el) => { return { id: el.id, title: el.name } });
        },
        getUsers()
        {
            return TeamsModule.teams.map(el => {
                return { id: el.id, title: `${el.first_name} ${el.last_name}`, img: el.avatar ? el.avatar : null, specialisations: el.specialisations };
            });
        },
				getAllUsers() {
						return TeamsModule.teams;
				},
    },
    async mounted() {
        if(!this.getUsers.length)
        {
            await this.loadUsers();
        } else
				{
					this.filterSelectedUsers();
				}
        await this.getOwner();
        await this.loadUserActivities();
        await this.loadSettings().finally(() => {
            this.pageLoaded = true;
						this.filterSelectedUsers();
        })
    },
    beforeDestroy()
    {
        // RolesModule.clearRoles();
        TeamsModule.clearTeams();
    },
    watch:
    {
        settings:
        {
            handler(newValue) {
                if (this.pageLoaded) {
                    this.updateChanges(newValue);
                }
								this.filterSelectedUsers();
            },
            deep: true,
        },
    },
}
</script>
<style lang="scss">
.content {
    .owner
    {
        .content__text
        {
            margin-bottom: 16px;
        }
    }
    .context-picker_off 
    {
        opacity: 0.4;
        pointer-events: none;
    }
    .user-history
    {
        .user-history__selector-bar-wrapper 
        {
            display: flex;
            justify-content: center;
            margin-bottom: 37px;
            .selector-bar
            {
                display: none;
                .selector-bar__backplate
                {
                    height: 35px;
                    margin-top: 2px;
                }
                margin-top: 24px;
                width: 100%;
                background: #1D1D1D;
                .selector-bar__option
                {
                    display: block;
                    padding: 6px 50px;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
    font-family: $font-family;
    font-style: normal;

    font-weight: 300;

    .content__subtitle {
        line-height: 22px;
        font-size: 18px;
        color: rgba(255, 255, 255, 0.8);
    }

    .content__text {
        margin-top: 8px;
        margin-bottom: 30px;
        font-size: 14px;
        line-height: 17px;
        color: rgba(255, 255, 255, 0.5);

    }

    .toggle-wrapper {
        display: flex;
        align-items: center;

        .toggle__label {
            margin-right: 10px;
        }
    }
}
</style>